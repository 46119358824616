import { UserAppointment } from 'common.interfaces';
import { DateTime } from 'luxon';

export const isAppointmentComingUp = (appointment: UserAppointment): boolean => {
  const minutesToAppointment = appointment.startDateTime.diff(DateTime.now(), ['minute']).get('minute');
  return Math.round(minutesToAppointment) === 5;
};

export const getUpcomingAndPastAppointments = (
  appointments: UserAppointment[],
  timeThreshold: DateTime = DateTime.now(),
): { past: UserAppointment[]; upcoming: UserAppointment[] } => {
  return {
    past: appointments.filter((appointment) => appointment.startDateTime < timeThreshold),
    upcoming: appointments.filter((appointment) => appointment.startDateTime >= timeThreshold),
  };
};

export const canPerformWriteAction = ({
  isImpersonated,
  allowImpersonationWriteActions,
}: {
  isImpersonated: boolean;
  allowImpersonationWriteActions: 'enabled' | 'disabled';
}): boolean => {
  return !isImpersonated || allowImpersonationWriteActions === 'enabled';
};
